import { Container } from '@material-ui/core';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Loader from '../../../../components/loader';
import db from '../../../../utils/dexie/driverappdb';
import ArticlesManagerView from './ArticlesManagerView';

const ArticlesManagerContainer = forwardRef(({ dropId, catalogState, readonly }, ref) => {

    const [isLoading, setIsLoading] = useState(true);
    const [appParams, setAppParams] = useState([]);
    const [articles, setArticles] = useState([]);
    const [localArticles, setLocalArticles] = useState([]);

    const articlesView = useRef();

    useEffect(() => {
        db.transaction('r', db.params, db.dropdetails2, db.dropdetailsdata2, db.articles, async () => {

            let params = await db.params.where('id').notEqual(0).first();
            setAppParams(params);

            const articlesPromise = db.dropdetails2
                .where('dropId').equals(parseInt(dropId))
                .toArray()
                .then(dropDetails => {
                    let articlePromises = dropDetails.map(dropDetail => db.articles.get(dropDetail.articleId));

                    return Promise.all(articlePromises).then(articles => {
                        articles.forEach(async (article, i) => {
                            Object.defineProperty(article, 'dropdetail', {
                                value: dropDetails[i],
                                enumerable: false
                            });

                            let articlesLocalDataQuery = dropDetails[i].deliveryOrderDetailId != null ? 
                                { deliveryOrderDetailId: dropDetails[i].deliveryOrderDetailId } :
                                { simulationDeliveryDetailId: dropDetails[i].simulationDeliveryDetailId };

                            await db.dropdetailsdata2.get(articlesLocalDataQuery)
                                .then(dropDetailsData => {
                                    Object.defineProperty(article, 'dropdetailsdata', {
                                        value: dropDetailsData,
                                        enumerable: false
                                    });
                                });
                        });
                        return articles;
                    }).then(articles => setArticles(articles));
                });

            const localArticlesPromise = db.dropdetailsdata2.where({ dropId: parseInt(dropId), createdLocally: 1 })
                .toArray(localData => {
                    var articlePromises = localData.map(data => db.articles.get(data.articleId));

                    return Promise.all(articlePromises).then(articles => {
                        articles.forEach((article, i) => {
                            Object.defineProperty(article, 'dropdetailsdata', {
                                value: localData[i],
                                enumerable: false
                            });
                        });
                        
                        return articles.sort((a, b) => {
                            if (a.seqNr == null)
                                return 1;
                            else if (b.seqNr == null)
                                return -1;
                            else
                                return a.seqNr - b.seqNr;
                        });
                    }).then(articles => setLocalArticles(articles));
                });

            return Promise.all(articlesPromise, localArticlesPromise);
        })
        .finally(() => setIsLoading(false));
    }, [dropId]);

    useImperativeHandle(ref, () => ({
        save: () => {
            articlesView.current.save();
        }
    }));

    return (
        <Container>
            { 
                isLoading ? 
                    <Loader /> : 
                    <ArticlesManagerView ref={articlesView} dropId={dropId} catalogState={catalogState}
                        articles={articles} localArticles={localArticles} readonly={readonly} 
                        articleInventoryEnabled={appParams.articleInventory} articleInventoryNoLimits={appParams.articleInventoryNoLimits} />
            }
        </Container>
    );

});

export default ArticlesManagerContainer;